import React from "react";
import styles from "./about.module.scss";
import {smallPicture, bigPicture, smartphone} from "../../assets"

function About(){
  return(
    <div className ={styles.About} id="QuemSomos">
      <div className={styles.tittleBox}>
        <h2 className={styles.tittle}>Sobre o app:</h2>
      </div>
      <div className={styles.aboutContent}>

        <div className={styles.aboutNumbers}>
          <div className={styles.leftAreaNumbers}>
            <div className={styles.info1}>
              <div className={styles.blockInfo1}>
                <h3 className={styles.title1}>+15</h3>
                <p className={styles.text1}>Famílias Impactadas</p>
              </div>
            </div>
            <div className={styles.info2}>
              <div className={styles.blockInfo2}>
                <h3 className={styles.title2}>7</h3>
                <p className={styles.text2}>Trilhas de Conhecimento</p>
              </div>
            </div>
          </div>
          <div className={styles.rightAreaNumbers}>
            <h3 className={styles.title3}>Nosso o aplicativo:</h3>
            <p className={styles.text3}>O aplicativo abrange tanto a parte técnica quanto os conhecimentos referentes a empreendedorismo. Onde o aluno poderá fazer os cursos e responder atividades de forma prática, simples e divertida</p>
          </div>
        </div>

        <div className={styles.aboutImpact} >
          <div className={styles.leftAreaImpact}>
            <h3 className={styles.title4}>Nossa Missão</h3>
            <p className={styles.text4}>Incentivar os jovens a olhar para o campo como uma possibilidade de um projeto de vida. Enxergar o rural como um um lugar para construir sua casa, constituir família, gerar renda. E que dê perspectivas e oportunidades de qualidade de vida.</p>
          </div>
          <div className={styles.rightAreaImpact}>
            <div className={styles.smallPicture}>
              <img src={smallPicture} alt="" />
            </div>
            <div className={styles.bigPicture}>
              <img src={bigPicture} alt="" />
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.tittleBox}>
        <h2 className={styles.tittle}>Valores:</h2>
      </div>
      
      <div className={styles.aboutContent}>
        <div className={styles.aboutNumbers}>
          <img className={styles.smartphone} src={smartphone} alt="" />
          <div className={styles.rightAreaNumbers}>
            <ul className={styles.list}>
              <li className={styles.listItem}>Sustentabilidade</li>
              <li className={styles.listItem}>Inovação</li>
              <li className={styles.listItem}>Empreendedorismo</li>
              <li className={styles.listItem}>Colaboração</li>
              <li className={styles.listItem}>Transparência</li>
              <li className={styles.listItem}>Impacto Positivo</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
