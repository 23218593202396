import React from 'react';
import './App.scss';
import { NavBar, Banner, About, Members, Footer, Contact } from './components/index';


const App = () => {
  return (
    <div className="App">
      <NavBar /> 
       <Banner />
      <About />
      <Members />
      <Contact />
      <Footer />
    </div>
  )
}

export default App;
