import React from "react";
import styles from './banner.module.scss';
import {banner} from "../../assets"

function Banner() {
  return (
    <div className={styles.Banner} id="Inicio">
        <div className={styles.BannerContent}>
          <div className={styles.imageContainer}>
            <img className={styles.imageBanner} src={banner} alt="Banner do site, com imagem ilustrativa de uma mão segurando um celular com o aplicativo aberto em meio a uma plantação" />
            <div className={styles.maskBanner} />
          </div>
          <spam className = {styles.text}>
            <p className={styles.title}>Eduflor</p>
            <p className={styles.subTitle}>Educando, de forma divertida, através da tecnologia os jovens agricultores da AGROFLOR</p>
          </spam>
        </div>
        
      </div>
  );
}

export default Banner;
