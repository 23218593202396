import React from "react";
import styles from "./navBar.module.scss";
import { logoHorizontal, download } from "../../assets";

import { FaBars, FaTimes } from 'react-icons/fa'

import { useRef } from "react";



function NavBar() {

  const navRef = useRef()
  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav")
  }

  return (
    <header className={styles.Navbar}>
      <nav className={styles.Menu} ref={navRef}>
        <div className={styles.LogoNav}>
          <a href= "#Inicio" >
            <img className={styles.LogoImg} src={logoHorizontal} alt="Logo"/>
          </a>
        </div>
        <div className={styles.NavBarList}>
          <ul className={styles.UlButtons}>
            <li ><a href= "#QuemSomos" >Quem Somos</a></li>
            <li ><a href= "#Parceiros" >Parceiros</a></li>
            <li ><a href= "#Contato" >Contato</a></li>
              <a className={styles.ButtonWhats} href="https://play.google.com/store/apps/details?id=com.eduflor" target="_blank" rel="noopener noreferrer">
                Baixar <img className={styles.iconWhats} src={download} alt="" />
              </a>
          </ul>
        </div>
        <button className={styles.nav_btn_close} onClick={showNavBar}>
          <FaTimes />
        </button>
      </nav>
      <button className={styles.nav_btn_open} onClick={showNavBar}>
        <FaBars />
      </button>
    </header>
  );
};

export default NavBar;
