import React from "react";
import styles from './footer.module.scss';
import { LogomarcaHorizotal2 } from "../../assets";


function Footer() {
  return (
    <div className={styles.footer} id="Contato">
        <div className={styles.footerContent}>
            <img className={styles.logo} src={LogomarcaHorizotal2} alt="" />
            <p className={styles.copyright}>Eduflor | Todos os direitos reservados</p>
            <div className={styles.footerInfo}>
                <a className={styles.footerLink} href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=eduflorapp@gmail.com">Email</a>
                <a className={styles.footerLink} href="https://play.google.com/store/apps/details?id=com.eduflor&pcampaignid=web_share">PlayStore</a>
                <a className={styles.footerLink} href="https://api.whatsapp.com/send?phone=558199578888" target="_blank" rel="noopener noreferrer">Whatsapp</a>
            </div>
        </div>
      </div>
  );
}

export default Footer;
