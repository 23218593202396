import React, { useState } from "react";
import styles from "./contact.module.scss";
import { manContact } from "../../assets";
import axios from 'axios';


function Contact(){

  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      subject,
      message,
    };

    try {
      await axios.post("https://eduflor.com.br/s/send_email/", data);
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Erro ao enviar a mensagem:", error);
    }
  };


  return (
    <div className={styles.Contact}>
        <div className={styles.tittleBox}>
          <h2 className={styles.tittle}>Contato:</h2>
        </div>
      <div className={styles.allContent}>
        <div className={styles.leftArea}>
          <div className={styles.image}>
            <img className={styles.backImage} src={manContact} alt="" />
          </div>            
        </div>

        <div className={styles.verticalLine}></div>

        <div className={styles.rightArea}>
          <h3 className={styles.titleMessage}>Envie-nos uma mensagem:</h3>
          <form className={styles.form} onSubmit={handleSubmit} >
            <input className={styles.email} type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail para contato" required />
            <input className={styles.email} type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Assunto" required />
            <textarea className={styles.message} value={message} onChange={(e) => setMessage(e.target.value)} rows="3" placeholder="Mensagem" required ></textarea>
            <div className={styles.submitMessageButtonArea}>
              <button className={styles.submitMessageButton} >Enviar</button>
            </div>
          </form>
        </div>
    </div>

  </div>
  );
}

export default Contact;
