// Basic Imports
import React, { Component } from "react";
import styles from "./members.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { agro, facepe, jh, sabia } from "../../assets";


class SimpleSlider extends Component {
  render() {
    const isMobile = window.innerWidth <= 768;
    const settings = {
      className: "center",
      centerMode: true,
      dots: false,
      infinite: true,
      centerPadding: isMobile ? "0": "60px",
      slidesToShow: isMobile ? 1 : 2,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      autoplay: true,
      speed: 10000,
      autoplaySpeed: 100,
      cssEase: "linear",
      arrows: false,
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <a className={styles.partner} href="https://agroflor.org.br/" target="_blank" rel="noopener noreferrer"><img src={agro} alt="Agro Flor" /></a>
          </div>
          <div>
            <a className={styles.partner} href="https://jhconsultoriacomercial.com.br/" target="_blank" rel="noopener noreferrer"><img src={jh} alt="JH Consultoria e Mentoria" /></a>
          </div>
          <div>
            <a className={styles.partner} href="https://www.facepe.br/" target="_blank" rel="noopener noreferrer"><img src={facepe} alt="Fundação de Amparo a Ciência e Tecnologia de Pernambuco" /></a>
          </div>
          <div>
            <a className={styles.partner} href="https://centrosabia.org.br/" target="_blank" rel="noopener noreferrer"><img src={sabia} alt="Centro Sabiá" /></a>
          </div>
        </Slider>
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

function Members(){

  return(
    <div className={styles.Members} id="Parceiros">
      <div className={styles.membersContent}>
        <div className={styles.tittleBox}>
          <h2 className={styles.tittle}>Parceiros:</h2>
        </div>
        <div className={styles.carousel}>
          <SimpleSlider />
        </div>

        <div class="bottomArea">
          <div className="buttomArea">
          </div>
        </div>
      </div>
    </div>
  );
};


export default Members
